/**
 * Copyright 2020 Telekom Multimedia Solutions
 *
 * Fetch _ga (and _ga2) cookie by (async) requesting (via Fetch-API) GoogleAnalyticsCookieServlet in order to circumvent
 * Safari's intelligent tracking prevention (ITP).
 */
class GoogleAnalyticsCookie {
    fetchCookie() {
        if (navigator.userAgent.match(/Safari/) && !navigator.userAgent.match(/Chrome|Chromium/)) {
            let url = '/bin/public/tracking/gacookie';
            const CURRENT_URL = new URL(document.location.href);
            const GA_PARAMETER = CURRENT_URL.searchParams.get('_ga');
            url = `${url}?hostname=${CURRENT_URL.hostname}`;
            if (GA_PARAMETER) {
                url = `${url}&_ga=${GA_PARAMETER}`;
            }
            fetch(url, {
                // added in order to send cookies
                credentials: 'same-origin',
            });
        }
    }
}

export default GoogleAnalyticsCookie;
