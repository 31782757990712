/**
 * Copyright 2020 Telekom Multimedia Solutions
 */
/** IMPORT(S) */
import GoogleAnalyticsCookie from './gacookie';
import Tracking from './tracking';

/** EXECUTION IMMEDIATELY */
(() => {
    new GoogleAnalyticsCookie().fetchCookie();
})();

/** EXECUTION AFTER FULL PAGE LOAD */
const readyStateCheckInterval = setInterval(() => {
    if (document.readyState === 'complete') {
        clearInterval(readyStateCheckInterval);

        const tracking = new Tracking();
        tracking.init();

        // Provide global access to static Tracking functions
        window.Tracking = {
            trackEvent: Tracking.trackEvent,
        };
    }
}, 10);
